<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import ReportsHeader from "@/components/reports-header.vue";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    ReportsHeader,
  },
  watch:{
    report_type(){
      this.genralLedger = []
    }
  },
  data() {
    return {
      report_type: true,
      self: this,
      limit: 20,
      page: 1,
      levelTwoAccounts: [],
      levelTwo: [],
      search: null,
      inputLevelTwo: null,
      totalBalance: 0,
      totalDebit: 0,
      totalCredit: 0,
      tot: 0,
      counter: 0,
      transactionCounter: 0,
      transactions: [],
      totalDebitBalance: 0,
      totalCreditBalance: 0,
      branch_id: null,
      data: {},

      // genral ledger
      genralLedger: [],
      account: {},
      levelThreeAccounts: [],
      subLedger: [],
      searchFilter: {},
      subLedgerType: "",
      sub: false,
    };
  },
  computed: {
    filteredAccounts() {
      return this.levelThreeAccounts.filter((account) => {
        return account;
      });
    },
  },
  methods: {
    selectedAccount() {
      this.http
        .post("level-three-chart-of-accounts-copy-1/accounts-setup/search", {
          search: this.account.search,
        })
        .then((res) => {
          if (res.status) {
            this.levelThreeAccounts = res.data.map((a) => {
              return this.$i18n.locale == "ar"
                ? a.code + "-" + a.name
                : a.code + "-" + a.name_en;
            });
          }
        });
    },
    getGenralLedgerResult() {
      this.http
        .post("transactions/genral-ledger", {
          from: this.searchFilter.from,
          to: this.searchFilter.to,
          account: this.report_type ? this.account : {},
        })
        .then((res) => {
          if (res.status) {
            this.totalCredit = 0;
            this.totalDebit = 0;
            this.genralLedger = res.data;
            this.genralLedger.forEach((transaction) => {
              if (transaction.type == "credit") {
                this.totalCredit += parseFloat(transaction?.value);
              } else {
                this.totalDebit += parseFloat(transaction?.value);
              }
            });
          }
        });
    },
    printReport() {
      window.print();
    },
  },
  created() { },
};
</script>

<template>
  <Layout class="no-print">
    <PageHeader :dir="$i18n.locale == 'ar' ? 'rtl' : ''" :title="$t('general_ledger.general-ledger')" />

    <div class="row">
      <div class="col-2"> <label for=""> {{ $t("general_ledger.specific_account") }}</label>
      </div>
      <div class="col-4">
        <input type="radio" class="from-control" v-model="report_type" :value="true">
      </div>
    </div>

    <div class="row">
      <div class="col-2"> <label for="">{{ $t("general_ledger.all_accounts") }}</label>
      </div>
      <div class="col-4">
        <input type="radio" class="from-control" v-model="report_type" :value="false">
      </div>
    </div>

    <div class="row">
      <form @submit.prevent="getGenralLedgerResult()" class="no-print">
        <div class="row mb-4">
          <!-- {{  report_type }} -->
          <div class="col" v-if="report_type">
            <label class="inline" for="fromJobTitle">{{
              $t("reports.account")
            }}</label>
            <AutoComplete @keyup="selectedAccount()" v-model="account.search" :suggestions="filteredAccounts"
              @complete="account.search = $event.query" style="color: #333 !important" />
          </div>
          <div class="col">
            <label class="inline" for="fromJobTitle">{{
              $t("report.from")
            }}</label>
            <input v-model="searchFilter.from" type="date" class="form-control" id="fromJobTitle" />
          </div>
          <div class="col">
            <label class="inline" for="toJobTitle">{{ $t("report.to") }}</label>
            <input v-model="searchFilter.to" type="date" class="form-control fromto" id="toJobTitle" />
          </div>
          <div class="col">
            <br />
            <button type="submit" class="btn btn-primary">
              {{ $t("popups.search") }}
            </button>
          </div>

          <div class="col" v-if="genralLedger.length > 0">
            <br />
            <button class="btn btn-success float-end" @click="printReport()">
              <i class="bx bx-printer"></i>
            </button>
          </div>
        </div>
      </form>

      <div class="row" v-if="genralLedger.length > 0 && report_type">
        <div class="card">
          <div class="card-body">
            <ReportsHeader :filterdPeriod="`${$i18n.locale == 'ar'
              ? 'من ' + searchFilter?.from + ' الى ' + searchFilter?.to
              : 'From ' + searchFilter?.from + ' To ' + searchFilter?.to
              }`" :reportName="$t('reports.genral')" :type="'general'" :accountNo="genralLedger[0]?.code" :accountName="$i18n.locale == 'ar'
    ? genralLedger[0]?.name
    : genralLedger[0]?.name_en
    " class="mb-4" />
            <div class="table-responsive">
              <table class="table table-centered table-nowrap table-striped table-hover align-middle"
                :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
                <thead>
                  <tr class="text-light" style="background-color: #2a3042 !important">
                    <!-- <th scope="col">{{ $t("general_ledger.account_id") }}</th> -->
                    <th scope="col">{{ $t("general_ledger.date") }}</th>
                    <!-- <th scope="col">{{ $t("general_ledger.particular") }}</th> -->
                    <th scope="col">{{ $t("general_ledger.tr_id") }}</th>
                    <th scope="col">{{ $t("general_ledger.descr") }}</th>
                    <th scope="col">{{ $t("general_ledger.debit") }}</th>
                    <th scope="col">{{ $t("general_ledger.credit") }}</th>
                    <th scope="col">{{ $t("general_ledger.balance") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="transaction in genralLedger" :key="transaction">
                    <!-- <td>{{ subLedger?.code }}</td> -->
                    <td>{{ transaction?.created.split("T")[0] }}</td>
                    <td>#{{ transaction?.tr_id }}</td>
                    <td>
                      {{
                        $i18n.locale == "ar"
                        ? transaction?.descr
                        : transaction?.descr_en
                      }}
                    </td>
                    <td>
                      {{
                        transaction?.type == "debit"
                        ? parseFloat(transaction?.value).toLocaleString()
                        : ""
                      }}
                    </td>
                    <td>
                      {{
                        transaction?.type == "credit"
                        ? parseFloat(transaction?.value).toLocaleString()
                        : ""
                      }}
                    </td>
                    <td></td>

                    <!-- <td>
                  {{ parseFloat(transaction?.amount).toLocaleString() }}
                </td> -->
                  </tr>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col" class="total-style">
                      {{ totalDebit.toLocaleString() }}
                    </th>
                    <th scope="col" class="total-style">
                      {{ totalCredit.toLocaleString() }}
                    </th>
                    <th scope="col" class="total-style">
                      {{
                        totalCredit > totalDebit
                        ? (
                          parseFloat(totalCredit) - parseFloat(totalDebit)
                        ).toLocaleString()
                        : (
                          parseFloat(totalDebit) - parseFloat(totalCredit)
                        ).toLocaleString()
                      }}
                    </th>
                  </tr>
                </tbody>
              </table>
              <table>
                <thead>
                  <th>{{ $t("reports.report_date") }} :</th>
                  <th>
                    {{
                      `${new Date().getFullYear()}-${new Date().getDate()}-${new Date().getDay()},
                                        ${new Date().getHours()}:${new Date().getMinutes()}`
                    }}
                  </th>
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="genralLedger.length > 0 && !report_type">
        <div class="card">
          <div class="card-body">
            <ReportsHeader :filterdPeriod="`${$i18n.locale == 'ar'
              ? 'من ' + searchFilter?.from + ' الى ' + searchFilter?.to
              : 'From ' + searchFilter?.from + ' To ' + searchFilter?.to
              }`" :reportName="$t('reports.genral')" :type="'general'" :accountNo="'-'" :accountName="$i18n.locale == 'ar' ?
    'الكل'
    : 'All'
    " class="mb-4" />
            <div class="table-responsive">
              <table class="table table-centered table-nowrap table-striped table-hover align-middle"
                :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
                <thead>
                  <tr class="text-light" style="background-color: #2a3042 !important">
                    <th scope="col">{{ $t("reports.code") }}</th>
                    <th scope="col">{{ $t("reports.report_name") }}</th>
                    <!-- <th scope="col">{{ $t("general_ledger.particular") }}</th> -->
                    <th scope="col">{{ $t("general_ledger.date") }}</th>
                    <th scope="col">{{ $t("general_ledger.tr_id") }}</th>
                    <th scope="col">{{ $t("general_ledger.descr") }}</th>
                    <th scope="col">{{ $t("general_ledger.debit") }}</th>
                    <th scope="col">{{ $t("general_ledger.credit") }}</th>
                    <th scope="col">{{ $t("general_ledger.balance") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="transaction in genralLedger" :key="transaction">
                    <td>
                      <span v-if="transaction.show_it">

                        {{ transaction?.code }}
                      </span>
                    </td>
                    <td>
                      <span v-if="transaction.show_it">
                        {{
                          $i18n.locale == "ar"
                          ? transaction?.name
                          : transaction?.name_en
                        }}
                      </span>
                    </td>
                    <td>{{ transaction?.created.split("T")[0] }}</td>
                    <td>#{{ transaction?.id }}</td>
                    <td>
                      {{
                        $i18n.locale == "ar"
                        ? transaction?.descr
                        : transaction?.descr_en
                      }}
                    </td>
                    <td>
                      {{
                        transaction?.type == "debit"
                        ? parseFloat(transaction?.value).toLocaleString()
                        : ""
                      }}
                    </td>
                    <td>
                      {{
                        transaction?.type == "credit"
                        ? parseFloat(transaction?.value).toLocaleString()
                        : ""
                      }}
                    </td>
                    <td></td>

                    <!-- <td>
                  {{ parseFloat(transaction?.amount).toLocaleString() }}
                </td> -->
                  </tr>
                  <tr>
                    <th scope="col" colspan="5"></th>

                    <th scope="col" class="total-style">
                      {{ totalDebit.toLocaleString() }}
                    </th>
                    <th scope="col" class="total-style">
                      {{ totalCredit.toLocaleString() }}
                    </th>
                    <th scope="col" class="total-style">
                      {{
                        totalCredit > totalDebit
                        ? (
                          parseFloat(totalCredit) - parseFloat(totalDebit)
                        ).toLocaleString()
                        : (
                          parseFloat(totalDebit) - parseFloat(totalCredit)
                        ).toLocaleString()
                      }}
                    </th>
                  </tr>
                </tbody>
              </table>
              <table>
                <thead>
                  <th>{{ $t("reports.report_date") }} :</th>
                  <th>
                    {{
                      `${new Date().getFullYear()}-${new Date().getDate()}-${new Date().getDay()},
                                        ${new Date().getHours()}:${new Date().getMinutes()}`
                    }}
                  </th>
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
  <div class="row print" v-if="genralLedger.length > 0">
    <div class="card">
      <div class="card-body">
        <ReportsHeader :filterdPeriod="`${$i18n.locale == 'ar'
          ? 'من ' + searchFilter?.from + ' الى ' + searchFilter?.to
          : 'From ' + searchFilter?.from + ' To ' + searchFilter?.to
          }`" :reportName="$t('reports.genral')" :type="'general'" :accountNo="genralLedger[0]?.code" :accountName="$i18n.locale == 'ar'
    ? genralLedger[0]?.name
    : genralLedger[0]?.name_en
    " class="mb-4" />
        <div class="table-responsive">
          <table class="table table-centered table-nowrap table-striped table-hover align-middle"
            :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
            <thead>
              <tr class="text-light" style="background-color: #2a3042 !important">
                <!-- <th scope="col">{{ $t("general_ledger.account_id") }}</th> -->
                <th scope="col">{{ $t("general_ledger.date") }}</th>
                <!-- <th scope="col">{{ $t("general_ledger.particular") }}</th> -->
                <th scope="col">{{ $t("general_ledger.tr_id") }}</th>
                <th scope="col">{{ $t("general_ledger.descr") }}</th>
                <th scope="col">{{ $t("general_ledger.debit") }}</th>
                <th scope="col">{{ $t("general_ledger.credit") }}</th>
                <th scope="col">{{ $t("general_ledger.balance") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="transaction in genralLedger" :key="transaction">
                <!-- <td>{{ subLedger?.code }}</td> -->
                <td>{{ transaction?.created.split("T")[0] }}</td>
                <td>#{{ transaction?.tr_id }}</td>
                <td>
                  {{
                    $i18n.locale == "ar"
                    ? transaction?.descr
                    : transaction?.descr_en
                  }}
                </td>
                <td>
                  {{
                    transaction?.type == "debit"
                    ? parseFloat(transaction?.value).toLocaleString()
                    : ""
                  }}
                </td>
                <td>
                  {{
                    transaction?.type == "credit"
                    ? parseFloat(transaction?.value).toLocaleString()
                    : ""
                  }}
                </td>
                <td></td>

                <!-- <td>
                  {{ parseFloat(transaction?.amount).toLocaleString() }}
                </td> -->
              </tr>
              <tr>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col" class="total-style">
                  {{ totalDebit.toLocaleString() }}
                </th>
                <th scope="col" class="total-style">
                  {{ totalCredit.toLocaleString() }}
                </th>
                <th scope="col" class="total-style">
                  {{
                    totalCredit > totalDebit
                    ? (
                      parseFloat(totalCredit) - parseFloat(totalDebit)
                    ).toLocaleString()
                    : (
                      parseFloat(totalDebit) - parseFloat(totalCredit)
                    ).toLocaleString()
                  }}
                </th>
              </tr>
            </tbody>
          </table>
          <table>
            <thead>
              <th>{{ $t("reports.report_date") }} :</th>
              <th>
                {{
                  `${new Date().getFullYear()}-${new Date().getDate()}-${new Date().getDay()},
                                ${new Date().getHours()}:${new Date().getMinutes()}`
                }}
              </th>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
/*
.table,
td {
  border: 1px solid black;
}
*/
.print {
  display: none;
}

@media print {
  .no-print {
    display: none;
  }

  .print {
    display: block;
  }
}
</style>
